






































import { Component } from 'vue-property-decorator';
import TableDialogFormComponent from '@common-src/mixins/table-dialog-form-component';
import { ProfessionalRelationEntityModel, ProfessionalRelationQueryModel } from '@common-src/entity-model/professional-relation-entity';
import ProfessionalRelationService from '@common-src/service/professional-relation';

@Component
export default class ProfessionalRelationListComponent extends TableDialogFormComponent<ProfessionalRelationEntityModel, ProfessionalRelationQueryModel> {
    ProfessionalRelationEntityModel = ProfessionalRelationEntityModel;

    created() {
        this.initTable({
            service: ProfessionalRelationService,
            queryModel: new ProfessionalRelationQueryModel(),
            tableColumns: ProfessionalRelationEntityModel.getTableColumns()
        });
        this.getList();
    }
}
